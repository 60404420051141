import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import axios from "axios";
import ConfigData from "./config/Config.json";
import DateDefault from "./DateDefault";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/; //perfect
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/; //perfect
const PHONE_REGEX = /^[0-9]?[0-9]*(?:[0-9]*)$/;

const REGISTER_URL = `${ConfigData.protoipaport}/api/auth/signup`;

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);



const Signup = () => {
  const { t } = useTranslation(["translation", "signup"]);

  const card = (
    <React.Fragment>
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography> */}
        <Typography variant="h5" component="div">
          {t("Header.h2", { ns: "signup" })}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Username:
        </Typography> */}
        <Typography variant="body2">
          {t("Instruction.ins1", { ns: "signup" })}
          <br />
          {t("Instruction.ins2", { ns: "signup" })}
          <br />
          {t("Instruction.ins3", { ns: "signup" })}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Password:
        </Typography> */}
        <Typography variant="body2">
          {t("Instruction.ins4", { ns: "signup" })}
          <br />
          {t("Instruction.ins5", { ns: "signup" })}
          <br />
          {t("Instruction.ins6", { ns: "signup" })}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </React.Fragment>
  );

  // List of roles
  var roleList = [
    { value: "patient", label: "Patient" },
    { value: "crspl", label: "Cardiac Rehab Specialist (CRSP)" },
    { value: "lhcp", label: "Local Health Care Provider (LHCP)" }
  ];

  // States for registration
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [date, setDate] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState(["-- Select Profile --"]);
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [gender, setGender] = useState("Male");
  const [address, setAddress] = useState("");

  // States for checking the errors and success
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  //eslint-disable-next-line
  const [success, setSuccess] = useState(false);
  const [resMSG, setResMSG] = useState("");

  //Country, State and City
  const [country, setCountry] = useState([]);
  const [countryid, setCountryId] = useState(-1);
  const [st, setSt] = useState([]);
  const [stateid, setStateId] = useState(-1);
  const [city, setCity] = useState([]);
  const [cityid, setCityId] = useState(-1);
  const [age, setAge] = useState(0);

  // Handling the name change
  const handleUserName = (e) => {
    setUsername(e.target.value);
    setSubmitted(false);
  };

  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setSubmitted(false);
  };

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setSubmitted(false);
  };

  // Handling the phone change
  const handlePhone = (e) => {
    if (PHONE_REGEX.test(e.target.value)) {
      setPhone(e.target.value);
      setSubmitted(false);
    }
  };

  //Handling the role change
  const handleRole = (e) => {
    setRole(e.target.value);
    setSubmitted(false);
  };

  // Handling the age change
  const handleAge = (e) => {
    setAge(e.target.value);
    setSubmitted(false);
  };

  // Handling the fname change
  const handleFName = (e) => {
    setFname(e.target.value);
    setSubmitted(false);
  };

  // Handling the mname change
  const handleMName = (e) => {
    setMname(e.target.value);
    setSubmitted(false);
  };

  // Handling the lname change
  const handleLName = (e) => {
    setLname(e.target.value);
    setSubmitted(false);
  };

  // Handling the gender change
  const handleGender = (e) => {
    setGender(e.target.value);
    setSubmitted(false);
  };

  // Handling the address change
  const handleAddress = (e) => {
    setAddress(e.target.value);
    setSubmitted(false);
  };

  // Handling the country change
  const handleCountry = (event) => {
    const getcountryid = event.target.value;
    //alert("|" + getcountryid + "|");
    if (getcountryid === "-- Select Country --") {
      setCountryId(0);
    } else {
      setCountryId(getcountryid);
    }
    setStateId(0);
    setSubmitted(false);
  };

  // Handling the state change
  const handleState = (event) => {
    if (event.target.value === "-- Select State --") {
      setStateId(0);
    } else {
      setStateId(event.target.value);
    }
    //setCityId(0);
    setSubmitted(false);
  };

  // Handling the state change
  const handleCity = (event) => {
    if (event.target.value === "-- Select City --") {
      setCityId(0);
    } else {
      setCityId(event.target.value);
    }
    //setCityId(0);
    setSubmitted(false);
  };

  useEffect(() => {
    setResMSG(resMSG);
  }, [resMSG]);

  //Country
  useEffect(() => {
    const getcountry = async () => {
      try {
        const res = await axios(`${ConfigData.protoipaport}/api/geo/country`);
        const getCon = await res.data;
        setCountry(await getCon);
      } catch (err) {
        alert("Error in loading the Country List : " + err.message);
      }
    };
    getcountry();
  }, []);

  //State
  useEffect(() => {
    const getstate = async () => {
      try {
        const resst = await axios(
          `${ConfigData.protoipaport}/api/geo/state/${countryid}`
        );
        const getSt = await resst.data;
        setSt(await getSt);
      } catch (err) {
        alert("Error in loading the State List : " + err.message);
      }
    };
    getstate();
  }, [countryid]);

  //City
  useEffect(() => {
    const getcity = async () => {
      try {
        const resct = await axios(
          `${ConfigData.protoipaport}/api/geo/city/${stateid}`
        );
        const getCit = await resct.data;
        setCity(await getCit);
      } catch (err) {
        alert("Error in loading the City List : " + err.message);
      }
    };
    getcity();
    //handleCity();
  }, [stateid]);

  //Role
  useEffect(() => {
    const getrole = async () => {
      setRole("-- Select Profile --");
    };
    getrole();
  }, []);

  //Age
  useEffect(() => {
    const getage = async () => {
      setAge(age);
    };
    getage();
  }, [age]);

  //Fname
  useEffect(() => {
    const getfname = async () => {
      setFname(fname);
    };
    getfname();
  }, [fname]);

  //Mname
  useEffect(() => {
    const getmname = async () => {
      setMname(mname);
    };
    getmname();
  }, [mname]);

  //Lname
  useEffect(() => {
    const getlname = async () => {
      setLname(lname);
    };
    getlname();
  }, [lname]);

  //Address
  useEffect(() => {
    const getaddress = async () => {
      setAddress(address);
    };
    getaddress();
  }, [address]);

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Creating the current date-time object to send for insertion
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    const v1 = USER_REGEX.test(username);
    const v2 = EMAIL_REGEX.test(email);
    const v3 = PWD_REGEX.test(password);
    let v4 = "";
    if (!phone.startsWith("0") && phone.length === 10) {
      v4 = phone;
    }

    if (
      username === "" ||
      email === "" ||
      password === "" ||
      role === "-- Select Profile --" ||
      date === "" || //Not valid here
      fname === "" ||
      lname === "" ||
      gender === "" || //Not Valid here
      age === "" ||
      phone === "" ||
      countryid === -1 ||
      countryid === 0 ||
      stateid === -1 ||
      stateid === 0 ||
      cityid === -1 ||
      cityid === 0 ||
      address === ""
    ) {
      setError(true);
      setError(
        "Error: Username, Password, Role, Email, First Name, Last Name, Age, Phone, Country, State, City and Address. Any or Either of these fields cannot be Blank!"
      );
    } else {
      if (!v1) {
        setError(true);
        setError("Error: Invalid Username Entry");
      } else {
        if (!v2) {
          setError(true);
          setError("Error: Invalid Email Entry");
        } else {
          if (!v3) {
            setError(true);
            setError("Error: Invalid Password Entry");
          } else {
            if (!v4) {
              setError(true);
              setError("Error: Please, enter a valid 10-digit phone number");
            } else {
              try {
                alert("Are you sure you want to signup / register the user?");
                const response = await axios.post(
                  REGISTER_URL,
                  {
                    dateTime: isoDate,
                    username: username,
                    email: email,
                    password: password,
                    role: [role],
                    dob: date,
                    age: age,
                    fname: fname,
                    mname: mname,
                    lname: lname,
                    gender: gender,
                    phone: phone,
                    country: countryid,
                    state: stateid,
                    city: cityid,
                    address: address,
                    accountstatus: "Active",
                    validityperiod: "365"
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "https://preprod.cardirehab.com"
                    },
                    withCredentials: true
                  }
                );

                //console.log(response?.accessToken);
                setResMSG(response.data.message);

                // These all three are working fine
                // alert(JSON.stringify(response?.data.message));
                // alert(response.data.message);
                // alert(JSON.stringify(response.data.message));

                setSuccess(true);
                setSubmitted(true);
                setError(false);

                //clear state and controlled inputs
                //need value attrib on inputs for this
                setUsername("");
                setPassword("");
                setEmail("");
                setRole("-- Select Profile --");
                setDate("");
                setAge(0);
                setFname("");
                setMname("");
                setLname("");
                setGender("Male");
                setPhone("");
                //setCountry([]);
                setCountryId(-1);
                setSt([]);
                setStateId(-1);
                setCity([]);
                setCityId(-1);
                setAddress("");
              } catch (err) {
                if (!err?.response) {
                  setError("Error: No Server Response");
                } else if (err.response?.status === 400) {
                  setError(true);
                  setError(err.response?.data.message);
                } else {
                  setError(
                    "Error: Registration Failed! Status code is: " +
                    err.response?.status +
                    "."
                  );
                }
                //errRef.current.focus();
              }
            }
          }
        }
      }
    }
  };

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none"
        }}
      >
        <font color="green">{resMSG}</font>
      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? "" : "none"
        }}
      >
        <font color="red">{error}</font>
      </div>
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <>
        <section id="signup" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{t("Header.h1", { ns: "signup" })}</h2>
              {/* <!-- <p>Please, provide the required / necessary details and signup.</p> -- */}
            </div>

            {/* Calling to the methods */}
            <div className="messages_signup">
              {errorMessage()}
              {successMessage()}
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <form
                  className="form-horizontal php-email-form"
                  data-aos="fade-up"
                >
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="control-label col-lg-1"
                        htmlFor="username"
                      >
                        <font color="red">*</font>
                        {t("Label.lblUsername", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          placeholder={t("placeholder.txtUsername", { ns: "signup" })}
                          type="text"
                          className="form-control"
                          id="username"
                          autoComplete="off"
                          //onChange={(e) => setUser(e.target.value)}
                          onChange={handleUserName}
                          value={username}
                          maxLength="24"
                          required
                        />
                      </div>
                      <label className="control-label col-lg-1" htmlFor="role">
                        <font color="red">*</font>
                        {t("Label.lblSignAs", { ns: "signup" })}
                      </label>
                      <div className="col-lg-7">
                        <select
                          className="form-control"
                          id="role"
                          onChange={(e) => handleRole(e)}
                          //onChange={handleRole}
                          required
                        >
                          <option value={"-- Select Profile --"}>
                            -- Select Profile --
                          </option>
                          {roleList.map((role) => (
                            <option value={role.value} key={role.value}>
                              {role.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="row">
                      <label
                        className="control-label col-lg-1"
                        htmlFor="password"
                      >
                        <font color="red">*</font>
                        {t("Label.lblPassword", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          placeholder={t("placeholder.txtPassword", { ns: "signup" })}
                          type="password"
                          //onChange={(e) => setPassword(e.target.value)}
                          onChange={handlePassword}
                          className="form-control"
                          id="password"
                          value={password}
                          autoComplete="off"
                          maxLength="24"
                          required
                        />
                      </div>

                      <label className="control-label col-lg-1" htmlFor="dob">
                        <font color="red">*</font>
                        {t("Label.lblDOB", { ns: "signup" })}
                      </label>
                      <DateDefault setAge={setAge} setDate={setDate} />

                      <label className="control-label col-lg-1" htmlFor="age">
                        {t("Label.lblAge", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          name="age"
                          className="form-control"
                          id="age"
                          value={age}
                          onChange={handleAge}
                          readOnly={true}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="row">
                      <label className="control-label col-lg-1" htmlFor="fname">
                        <font color="red">*</font>
                        {t("Label.lblFName", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          placeholder={t("placeholder.txtFName", { ns: "signup" })}
                          type="text"
                          name="fname"
                          className="form-control"
                          id="fname"
                          value={fname}
                          maxLength="20"
                          onChange={handleFName}
                          required
                        />
                      </div>

                      <label className="control-label col-lg-1" htmlFor="mname">
                        {t("Label.lblMName", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          placeholder={t("placeholder.txtMName", { ns: "signup" })}
                          type="text"
                          name="mname"
                          className="form-control"
                          id="mname"
                          value={mname}
                          maxLength="20"
                          onChange={handleMName}
                        />
                      </div>

                      <label className="control-label col-lg-1" htmlFor="lname">
                        <font color="red">*</font>
                        {t("Label.lblLName", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          placeholder={t("placeholder.txtLName", { ns: "signup" })}
                          type="text"
                          name="lname"
                          className="form-control"
                          id="lname"
                          value={lname}
                          maxLength="20"
                          onChange={handleLName}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="row">
                      <label className="control-label col-lg-1" htmlFor="email">
                        <font color="red">*</font>
                        {t("Label.lblEmail", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          placeholder={t("placeholder.txtEmail", { ns: "signup" })}
                          type="email"
                          //onChange={(e) => setEmail(e.target.value)}
                          onChange={handleEmail}
                          className="form-control"
                          name="email"
                          id="email"
                          value={email}
                          maxLength="50"
                          required
                        />
                      </div>
                      <label className="control-label col-lg-1" htmlFor="gender">
                        {t("Label.lblGender", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <select
                          className="form-control"
                          name="gender"
                          id="gender"
                          onChange={(e) => handleGender(e)}
                          required
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <label className="control-label col-lg-1" htmlFor="phone">
                        <font color="red">*</font>
                        {t("Label.lblPhone", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <input
                          placeholder={t("placeholder.txtPhone", { ns: "signup" })}
                          type="text"
                          className="form-control"
                          name="phone"
                          onChange={handlePhone}
                          id="phone"
                          maxLength="10"
                          value={phone}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="row">
                      <label className="control-label col-lg-1" htmlFor="country">
                        <font color="red">*</font>
                        {t("Label.lblCountry", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <select
                          className="form-control"
                          id="country"
                          onChange={(e) => handleCountry(e)}
                          required
                        >
                          <option value={null}>-- Select Country --</option>
                          {country.map((countryget) => (
                            <option
                              key={countryget.countryId}
                              value={countryget.countryId}
                            >
                              {countryget.countryName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <label className="control-label col-lg-1" htmlFor="pincode">
                        <font color="red">*</font>
                        {t("Label.lblState", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <select
                          className="form-control"
                          id="state"
                          onChange={(e) => handleState(e)}
                          required
                        >
                          <option value={null}>-- Select State --</option>
                          {st.map((stateget) => (
                            <option key={stateget.id} value={stateget.id}>
                              {stateget.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <label className="control-label col-lg-1" htmlFor="city">
                        <font color="red">*</font>
                        {t("Label.lblCity", { ns: "signup" })}
                      </label>
                      <div className="col-lg-3">
                        <select
                          className="form-control"
                          id="city"
                          onChange={(e) => handleCity(e)}
                          required
                        >
                          <option value={null}>-- Select City --</option>
                          {city.map((cityget, index) => (
                            <option key={index} value={cityget.cityid}>
                              {cityget.cityname}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="row">
                      <label className="control-label col-lg-1" htmlFor="address">
                        <font color="red">*</font>
                        {t("Label.lblAddress", { ns: "signup" })}
                      </label>
                      <div className="col-lg-11">
                        <input
                          placeholder={t("placeholder.txtAddress", { ns: "signup" })}
                          type="text"
                          className="form-control"
                          name="address"
                          maxLength="150"
                          id="address"
                          value={address}
                          onChange={handleAddress}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="my-3">
                    <div className="loading">{/* Loading */}</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      {/* Your message has been sent. Thank you! */}
                    </div>
                  </div>

                  <div>
                    <Box sx={{ minWidth: 275 }}>
                      <Card className="infosignup" variant="outlined">
                        {card}
                      </Card>
                    </Box>
                  </div>
                  <br />
                  <div className="text-center">
                    <button onClick={handleSubmit} type="submit">
                      {t("Label.lblSignup", { ns: "signup" })}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    </Suspense>
  );
};

export default Signup;
